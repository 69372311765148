@import "src/scss/module";

.HomeAboutContent {
  position: relative;

  .bg_1 {
    top: 40%;
    left: 0;
  }

  .bg_2 {
    right: 0;
    top: -20%;
  }
}
